import React, { FC } from 'react';
import Head from 'next/head';

import { Address, buildAddress, markup } from 'seo';

export interface AccommodationJsonLdProps {
	name: string;
	description?: string;
	numberOfRooms?: number;
	numberOfBedrooms?: number;
	numberOfBathroomsTotal?: number;
	code?: string;
	url?: string;
	image?: string;
	hasMap?: string;
	latitude?: string;
	longitude?: string;
	address?: Address;
}

export const AccommodationJsonLd: FC<AccommodationJsonLdProps> = ({
	name,
	description,
	numberOfRooms,
	numberOfBedrooms,
	numberOfBathroomsTotal,
	code,
	url,
	image,
	hasMap,
	latitude,
	longitude,
	address
}) => {
	// https://schema.org/Accommodation
	const jslonld = `{
		"@context" : "http://schema.org",
		"@type": "Accommodation",
		${description ? `"description": "${description}",` : ''}
		${numberOfRooms ? `"numberOfRooms": "${numberOfRooms}",` : ''}
		${
			numberOfBathroomsTotal
				? `"numberOfBathroomsTotal": "${numberOfBathroomsTotal}",`
				: ''
		}
		${numberOfBedrooms ? `"numberOfBedrooms": "${numberOfBedrooms}",` : ''}
		${url ? `"url": "${url}",` : ''}
		${image ? `"image": "${image}",` : ''}
		${hasMap ? `"hasMap": "${hasMap}",` : ''}
		${latitude ? `"latitude": "${latitude}",` : ''}
		${longitude ? `"longitude": "${longitude}",` : ''}
		${address ? buildAddress(address) : ''}
		"name": "${name}"
	}`;

	return (
		<Head>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={markup(jslonld)}
				key={`jsonld-accommodation${code ? `-${code}` : ''}`}
			/>
		</Head>
	);
};

import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

import buildTags from './buildTags';
import { NextSeoProps } from '../types';
import { seoConstants } from 'seo';
import { getHostname } from 'seo/utils/getHostname';

export const NextSeo: React.FC<NextSeoProps> = props => {
	const router = useRouter();

	const {
		title = seoConstants.mainTitle,
		noindex = false,
		nofollow,
		robotsProps,
		description = seoConstants.mainDescription,
		canonical = getHostname() + router.asPath,
		openGraph = {},
		facebook,
		twitter,
		additionalMetaTags,
		titleTemplate,
		mobileAlternate,
		languageAlternates,
		additionalLinkTags
	} = props;

	const combinedOg = Object.assign(
		openGraph,
		!openGraph?.title && {
			title: title
		},
		!openGraph?.description && {
			description: seoConstants.mainDescription
		},
		!openGraph?.images && {
			images: [
				{
					url: `${getHostname()}/assets/images/litto-opengraph.png`,
					alt: 'Litto'
				}
			]
		},
		!openGraph?.url && {
			url: getHostname() + router.asPath
		},
		!openGraph?.type && {
			type: 'website'
		}
	);
	return (
		<Head>
			{buildTags({
				title,
				noindex,
				nofollow,
				robotsProps,
				description,
				canonical,
				facebook,
				openGraph: combinedOg,
				additionalMetaTags,
				twitter,
				titleTemplate,
				mobileAlternate,
				languageAlternates,
				additionalLinkTags
			})}
			{/* <meta name="twitter:card" content="summary_large_image" /> */}
			{/* <meta name="twitter:site" content="@visit_trogir" /> */}
			{/* <meta name="twitter:title" content={title} /> */}
			{/* <meta name="twitter:description" content={description} /> */}
			{/* <meta name="twitter:url" content={getHostname() + router.asPath} /> */}
			{/* <meta
				name="twitter:image"
				content={
					openGraph?.images?.[0].url ||
					`${getHostname()}/assets/images/litto-opengraph.png`
				}
			/> */}
		</Head>
	);
};

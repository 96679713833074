import { ReactNode } from 'react';

import { Footer, PlainHeader } from 'components/shared';

interface IPlainLayout {
	children: ReactNode;
	hideHeaderBackButton?: boolean;
}

export const PlainLayout: React.FC<IPlainLayout> = ({
	children,
	hideHeaderBackButton = false
}) => {
	return (
		<>
			<PlainHeader hideBackButton={hideHeaderBackButton} />
			{children}
			<Footer />
		</>
	);
};

import React from 'react';
import Head from 'next/head';

import { markup } from 'seo';

interface IWebsiteJsonLdProps {
	keyOverride?: string;
}

export const WebsiteJsonld: React.FC<IWebsiteJsonLdProps> = ({
	keyOverride
}) => {
	const jsonld = `{
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": "https://www.litto.co/",
        "potentialAction": {
          "@type": "SearchAction",
          "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://www.litto.co/channel/accommodations?query={search_term_string}"
          },
          "query-input": {
              "@type": "PropertyValueSpecification",
              "valueRequired": "http://schema.org/True",
              "valueName": "search_term_string"
          }
        }
    }`;
	return (
		<Head>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={markup(jsonld)}
				key={`jsonld-website${keyOverride ? `-${keyOverride}` : ''}`}
			/>
		</Head>
	);
};

import { Address } from '../types';

export const buildAddress = (address: Address) => `
  "address": {
    "@type": "PostalAddress",
	${address.streetAddress ? `"streetAddress": "${address.streetAddress}",` : ''}
	${
		address.addressLocality
			? `"addressLocality": "${address.addressLocality}",`
			: ''
	}
    ${
			address.addressRegion
				? `"addressRegion": "${address.addressRegion}",`
				: ''
		}
	${address.postalCode ? `"postalCode": "${address.postalCode}",` : ''}
	${address.addressCountry ? `"addressCountry": "${address.addressCountry}"` : ''}
  },
`;

import { ReactNode } from 'react';
import Link from 'next/link';

import { useMediaQuery } from 'litto-lib/hooks';
import { Header, Footer } from 'components/shared';
import { SvgLogo } from 'litto-lib/assets';

interface IBasicLayoutProps {
	children: ReactNode;
	noHeader?: boolean;
}

export const BasicLayout: React.FC<IBasicLayoutProps> = ({
	children,
	noHeader = false
}) => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	return (
		<>
			{!noHeader && (
				<Header
					fullWidth
					withBack={isMobile ? true : false}
					slotCenter={
						isMobile ? (
							<Link href="/">
								<a className="absolute left-1/2 -translate-x-1/2 transform">
									<SvgLogo className="text-primary-500" />
								</a>
							</Link>
						) : undefined
					}
				/>
			)}
			{children}
			<Footer />
		</>
	);
};

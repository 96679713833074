import React from 'react';
import Head from 'next/head';

import {
	Address,
	buildAddress,
	buildGeoCoordinates,
	buildContactPoint,
	ContactPoint,
	GeoCoordinates,
	markup,
	OrganizationCategory
} from 'seo';

interface IOrganizationJsonLdProps {
	organizationType?: OrganizationCategory;
	id?: string;
	name: string;
	logo?: string;
	url: string;
	legalName?: string;
	sameAs?: string[];
	address?: Address;
	geoCoordinates?: GeoCoordinates;
	contactPoints?: ContactPoint[];
}

export const OrganizationJsonLd: React.FC<IOrganizationJsonLdProps> = ({
	organizationType = 'Organization',
	id,
	name,
	logo,
	url,
	legalName,
	sameAs = [],
	address,
	geoCoordinates,
	contactPoints = []
}) => {
	const jsonld = `{
		"@context": "https://schema.org",
		"@type": "${organizationType}",
		${id ? `"@id": "${id}",` : ''}
		${logo ? `"logo": "${logo}",` : ''}
		${legalName ? `"legalName": "${legalName}",` : ''}
		"name": "${name}",
		${address ? buildAddress(address) : ''}
		${geoCoordinates ? buildGeoCoordinates(geoCoordinates) : ''}
		${
			sameAs.length > 0
				? `"sameAs": [${sameAs.map(alias => `"${alias}"`).join(',')}],`
				: ''
		}
		${
			contactPoints.length > 0
				? `"contactPoints": [${buildContactPoint(contactPoints)}],`
				: ''
		}
		"url": "${url}"
	}`;
	return (
		<Head>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={markup(jsonld)}
				key="jsonld-organization-page"
			/>
		</Head>
	);
};

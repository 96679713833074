import { ReactNode } from 'react';
import { useRouter } from 'next/router';

import { Header, HeaderSteps, Footer } from 'components/shared';

import { useOrderStateContext } from 'context';

import { SvgCheckIcon, SvgEdit, SvgSafe } from 'litto-lib/assets';

interface ICheckoutLayout {
	children: ReactNode;
}

export const CheckoutLayout: React.FC<ICheckoutLayout> = ({ children }) => {
	const orderState = useOrderStateContext();
	const { orderKey } = orderState;
	const router = useRouter();

	return (
		<>
			<Header
				withBack={router.pathname !== '/checkout/thank-you/[orderKey]'}
				slotCenter={
					<HeaderSteps
						className="mx-auto -translate-x-7.5 transform"
						steps={[
							{
								label: 'Details',
								icon: <SvgEdit className="pin-c h-3 w-3 text-white" />,
								href: '/checkout/personal-info/[orderKey]',
								as: `/checkout/personal-info/${orderKey}`
							},
							{
								label: 'Payment',
								icon: <SvgSafe className="pin-c h-3 w-3 text-white" />,
								href: '/checkout/payment-details/[orderKey]',
								as: `/checkout/payment-details/${orderKey}`
							},
							{
								label: 'Confirmation',
								icon: <SvgCheckIcon className="pin-c h-3 w-3 text-white" />,
								href: '/checkout/thank-you/[orderKey]',
								as: `/checkout/thank-you/${orderKey}`
							}
						]}
					/>
				}
				hasSearch={false}
			/>
			{children}
			<Footer isMini={true} />
		</>
	);
};

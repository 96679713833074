import { IAccommodationRead } from 'services/shop/accommodation/interfaces';
import { mapItemToImpression } from '.';

export const mapItemsToImpression = (
	listName: string,
	items: IAccommodationRead[],
	startingPosition?: number
) => {
	return items.map((item: IAccommodationRead, i: number) => {
		const itemImpression = mapItemToImpression(item);
		return {
			...itemImpression,
			list: `${listName}`,
			position: (startingPosition || 0) + i + 1
		};
	});
};
